<template>
	<div class="grid">
		<Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
		<Menu ref="menu1" :popup="true" :model="items"></Menu>
		<div>
				<DynamicDialog />
			</div>
		<div class="col-12">
			<div class="card">
				meigaranewsrecentl {{perf}}
				<br>codekey {{selectedCodeKey}}
				<DataView :value="dataviewValue" :layout="layout" :paginator="true" :rows="20" :sortOrder="sortOrder" :sortField="sortField"
				paginatorTemplate="FirstPageLink PrevPageLink NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,20,50,75,100]"
        currentPageReportTemplate="{first}~{last} of {totalRecords}">
					<template #header>
						<div class="grid grid-nogutter">
							<div class="col-6 text-left">	
								<span class="p-input-icon-left">
									<i class="pi pi-search" />
									<InputText type="text" v-model="meigaraKey" placeholder="銘柄コード" v-on:keydown.enter="onSearchClick()" />
								</span>
							</div>
							<div class="col-6 text-right">
								<DataViewLayoutOptions v-model="layout" />
							</div>
						</div>
						<div class="text-left ml-1 mt-2">
							<Accordion class="accordion-r">
								<AccordionTab header="ブックマークから探す">
									<Chip class="chip-r" :class="getChipClass('0000')">
										<router-link :to="{name:'meigaranewsrecentl',params:{code:layout+'m0000'}}">
											<span class="mr-1" :class="getChipTextClass('0000')">All</span>
										</router-link>
									</Chip>
									<Chip class="chip-r" :class="getChipClass('0001')">
										<router-link :to="{name:'meigaranewsrecentl',params:{code:layout+'m0001'}}">
											<span class="mr-1" :class="getChipTextClass('0001')">My All</span>
										</router-link>
									</Chip>									
									<Chip class="chip-r" :class="getChipClass('0002')">
										<router-link :to="{name:'meigaranewsrecentl',params:{code:layout+'m0002'}}">
											<span class="mr-1" :class="getChipTextClass('0002')">My Recent5</span>
										</router-link>
									</Chip>
									<template v-for="(bookmark, index) in bookmarks" :key="index">
										<Chip class="chip-r" :class="getChipClass(bookmark.bookmark_cd)">
											<router-link :to="{name:'meigaranewsrecentl',params:{code:layout+'m'+bookmark.bookmark_cd}}">
												<span class="mr-1" :class="getChipTextClass(bookmark.bookmark_cd)">{{bookmark.bookmark_cd}} {{bookmark.bookmark_name_ja}}</span>									
											</router-link>
										</Chip>
									</template>
								</AccordionTab>
							</Accordion>
						</div>
					</template>
					<template #list="slotProps">					
						<div class="col-12">
							<div class="flex flex-column md:flex-row align-items-left p-3 w-full">
								<div class="flex-1 text-left md:text-left">
									<div class="flex align-items-center justify-content-between">
										<div>
											<router-link :to="{name:'meigaranewsrecentl',params:{code:'lista0'}}">
												<i class="pi pi-tags mr-1"></i>
											</router-link>
											<router-link class="mr-1" :to="{name:'meigaranewsrecentl',params:{code:'lists'+slotProps.data.news_sentiment}}">
												<span :class="'sentiment-badge status-' + getBadgeStatusText(slotProps.data.news_sentiment)">{{getFaceSymbol(slotProps.data.news_sentiment)}}</span>
											</router-link>
											<Chip class="chip-r" :class="getChipClass(slotProps.data.news_type)">
												<router-link :to="{name:'meigaranewsrecentl',params:{code:'listt'+slotProps.data.news_type}}">
													<span :class="getChipTextClass(slotProps.data.news_type)">{{slotProps.data.news_type}}</span>
												</router-link>
											</Chip>
											<template v-for="(tag, index) in slotProps.data.news_tags" :key="index">
												<Chip class="chip-r" :class="getChipClass(tag)">
													<router-link :to="{name:'meigaranewsrecentl',params:{code:'listz'+tag}}">
														<span :class="getChipTextClass(tag)">{{tag}}</span>
													</router-link>
												</Chip>
											</template>
										</div>

										<Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setMeigaras($event, slotProps.data.meigaras)"></Button>
									</div>
									<div><span class="mr-1 subinfo-span">{{slotProps.data.news_datetime}} {{slotProps.data.info_source}} {{slotProps.data.info_source2}}</span></div>
									<div class="text-2xl mb-1">
										<a v-bind:href="slotProps.data.news_url" target="_blank">{{slotProps.data.news_overview}}</a>
									</div>
									<div class="mb-1">
										<template v-for="(meigara, index) in slotProps.data.meigaras" :key="index">
											<Chip class="chip-r mr-1" :class="getChipClass(meigara.meigara_cd)">
												<img class="img_link" :src="'https://tradersr.com' + meigara.meigara_trendchart_file" @click="gotoMeigarachart($event, meigara.meigara_cd)"/>
												<router-link :to="{name:'meigaranewsrecentl',params:{code:'listm'+meigara.meigara_cd}}">
													<span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">{{meigara.market_name_short}}</span>
													<span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">{{meigara.meigara_cd}} {{meigara.meigara_name}}</span>											
													<span class="mr-1" :class="getChipTextClass(meigara.meigara_cd)">¥{{meigara.close}}</span> 
													<span :class="getPriceClass(meigara.v1d_mean)">{{meigara.v1d_mean}}%</span>
												</router-link>
											</Chip>
										</template>
									</div>
									<a href="#" @click.prevent.stop="onShow(slotProps.data)"><span class="span-highlight">...more</span></a>
									<div class="flex align-items-center justify-content-between"> 
										<span class="subinfo-span">価@{{slotProps.data.odate}} {{slotProps.data.otime}}</span>
										<span class="subinfo-span">{{slotProps.data.meigaras_cnt}}銘柄
											<i class="pi pi-copy" @click="copyToClipboard(slotProps.data.meigaras)"></i>
										</span>
									</div>
								</div>
							</div>
						</div>
					</template>

					<template #grid="slotProps">											
						<div class="col-12 md:col-3">
							<div class="card m-1 border-0">
								<div class="flex align-items-center justify-content-between">
									<div>
										<router-link :to="{name:'meigaranewsrecentl',params:{code:'grida0'}}">
											<i class="pi pi-tags mr-1"></i>
										</router-link>
										<router-link class="mr-1" :to="{name:'meigaranewsrecentl',params:{code:'grids'+slotProps.data.news_sentiment}}">
											<span :class="'sentiment-badge status-' + getBadgeStatusText(slotProps.data.news_sentiment)">{{getFaceSymbol(slotProps.data.news_sentiment)}}</span>
										</router-link>
										<Chip class="chip-r" :class="getChipClass(slotProps.data.news_type)">
											<router-link :to="{name:'meigaranewsrecentl',params:{code:'gridt'+slotProps.data.news_type}}">
												<span :class="getChipTextClass(slotProps.data.news_type)">{{slotProps.data.news_type}}</span>
											</router-link>
										</Chip>
										<template v-for="(tag, index) in slotProps.data.news_tags" :key="index">
											<Chip class="chip-r" :class="getChipClass(tag)">
												<router-link :to="{name:'meigaranewsrecentl',params:{code:'gridz'+tag}}">
													<span :class="getChipTextClass(tag)">{{tag}}</span>
												</router-link>
											</Chip>
										</template>
									</div>
									<Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setMeigaras($event, slotProps.data.meigaras)"></Button>
								</div>
								<div class="text-left">
									<div><span class="mr-1 subinfo-span">{{slotProps.data.news_datetime}} {{slotProps.data.info_source}} {{slotProps.data.info_source2}}</span></div>
									<div class="text-2xl mb-1"><a v-bind:href="slotProps.data.news_url" target="_blank">{{slotProps.data.news_overview}}</a></div>
                  <div>
										<Chip class="chip-r mr-1 mb-1" :class="getChipClass(slotProps.data.meigaras[0].meigara_cd)">
											<router-link :to="{name:'meigaranewsrecentl',params:{code:'gridm'+slotProps.data.meigaras[0].meigara_cd}}">
												<span class="mr-1" :class="getChipTextClass(slotProps.data.meigaras[0].meigara_cd)">{{slotProps.data.meigaras[0].market_name_short}}</span>
												<span :class="getChipTextClass(slotProps.data.meigaras[0].meigara_cd)">{{slotProps.data.meigaras[0].meigara_cd}} {{slotProps.data.meigaras[0].meigara_name}}</span>
												<span class="ml-1 mr-1" :class="getChipTextClass(slotProps.data.meigaras[0].meigara_cd)">¥{{slotProps.data.meigaras[0].close}}</span> 
												<span :class="getPriceClass(slotProps.data.meigaras[0].v1d_mean)">{{slotProps.data.meigaras[0].v1d_mean}}%</span>
											</router-link>
										</Chip>
										<!-- <span v-if="slotProps.data.meigaras_cnt > 1">
											<a href="#" @click.prevent.stop="onShow(slotProps.data)"><span class="span-highlight">...more</span></a>
										</span> -->
									</div>
									<table border="0">
										<tr>
											<td class="rotate_text" vAlign="bottom">
												<span class="date-span">{{getTrendchartDate(slotProps.data.meigaras[0].meigara_trendchart_sdate)}}</span>
											</td>
											<td>
												<router-link :to="{name:'meigarachart',params:{code:slotProps.data.meigaras[0].meigara_cd}}">
													<img class="trendchart-img" :src="'https://tradersr.com' + slotProps.data.meigaras[0].meigara_trendchart_file"/>
												</router-link>
											</td>
											<td class="rotate_text"  vAlign="top" style="padding:0">
												<span class="date-span">{{getTrendchartDate(slotProps.data.meigaras[0].meigara_trendchart_edate)}}</span>
											</td>
										</tr>
									</table>
									<a href="#" @click.prevent.stop="onShow(slotProps.data)"><span class="span-highlight">...more</span></a>				
									<div class="flex align-items-center justify-content-between"> 
										<span class="subinfo-span">価@{{slotProps.data.odate}} {{slotProps.data.otime}}</span>
										<span class="subinfo-span">{{slotProps.data.meigaras_cnt}}銘柄 
											<i class="pi pi-copy" @click="copyToClipboard(slotProps.data.meigaras)"></i>
										</span>
									</div>
								</div>
							</div>
						</div>
					</template>
				</DataView>
			</div>
		</div>

	</div>
</template>

<script>
	import MeigaranewsrecentlService from "../service/MeigaranewsrecentlService";
	import News from '@/components/News';

	export default {
		data() {
			return {
				copiedMeigaras: null,
				bookmarks: null,
				selectedCodeKey: null,
				// selectedNewsType: null,
				meigaraKey: null,
				mcd: null,
        perf: null,
				dataviewValue: null,
				layout: 'grid', // g(grid) or l(list)
				sortKey: null,
				sortOrder: null,
				sortField: null,
				clipboardText: null,
				clickedNews: null,
				codeKey: null,
				sortOptions: [
					{label: 'Price High to Low', value: '!price'},
					{label: 'Price Low to High', value: 'price'},
				],
				items: [
					{
						label: '銘柄をコピー',
						command: () => {
							// this.$router.push('/meigarasearch_ext/' + this.selectedBookmarkCd);
							this.copyToClipboard(this.copiedMeigaras);
						}
					},
				],
			}
		},
		meigaranewsrecentlService: null,
		created() {
			this.meigaranewsrecentlService = new MeigaranewsrecentlService();
		},
		mounted() {
			this.layout  = this.$route.params.code.substring(0, 4);

			this.meigaranewsrecentlService.getNewsInfo(this.$route.params.code)
				.then(d => {
					this.bookmarks = d.data.bookmarks;
					this.dataviewValue = d.data.newslist;
					this.perf = d.perf;

					this.selectedCodeKey = this.$route.params.code.substring(5, this.$route.params.code.length);
				}
			);			
		},
		methods: {
			onSortChange(event){
				const value = event.value.value;
				const sortValue = event.value;

				if (value.indexOf('!') === 0) {
					this.sortOrder = -1;
					this.sortField = value.substring(1, value.length);
					this.sortKey = sortValue;
				}
				else {
					this.sortOrder = 1;
					this.sortField = value;
					this.sortKey = sortValue;
				}
			},
			getChipClass(key){
				return [
					{'a-active':this.selectedCodeKey===key},
				]
			},
			getChipTextClass(key){
				return [
					{'text-active':this.selectedCodeKey===key},
				]
			},
			getNewstagClass(key){
				return [
					{'a-active':this.selectedCodeKey===key},
				]
			},
			getNewstagTextClass(key){
				return [
					{'active':this.selectedCodeKey===key},
				]
			},
			getBadgeTextClass_news(key){
				return [
					{'text-active':this.selectedCodeKey===key},
				]
			},
			getBadgeStatusText(key){
				let ret = null;

				if(this.selectedCodeKey===key){
					ret = 'active';
				}else{
					ret = key.toLowerCase();
				}

				return ret;
			},
			getTagStatusText(key){
				let ret = null;

				if(this.selectedCodeKey===key){
					ret = 'active';
				}else{
					ret = 'default';
				}

				return ret;
			},
			getPriceClass(data) {
				return [
					{
						'positive': data > 0,
						'negative': data < 0,
						'neutral': data == 0,
					}
				];
      },
			getFaceSymbol(sentiment) {
				let ret = null;

				if(sentiment == 'P'){
					ret = '好';
				}else if(sentiment == 'N'){
					ret = '悪';
				}else if(sentiment == 'B'){
					ret = '？';
				}else{
					ret = '未';
				}

				return ret;
			},
			getTrendchartDate(param) {
				let tcdate = null;
				if(param.length == 10){
					tcdate = param.substr(5, 5);
					tcdate = tcdate.replace('-','/');
				}
				return tcdate;
			},
			onSearchClick() {
				if(this.meigaraKey && this.meigaraKey.length > 1) {
					let code = this.layout + 'm' + this.meigaraKey;   
					this.meigaranewsrecentlService.getNewsInfo(code)
					.then(d => {
						this.bookmarks = d.data.bookmarks;
						this.dataviewValue = d.data.newslist;
						this.perf = d.perf;
					}
					);	
					// this.currentMeigaraKey = this.meigaraKey;
					this.selectedCodeKey = this.meigaraKey;
					this.meigaraKey = null;
				}
			},
			gotoMeigarachart(event, mcd) {
				this.$router.push('/meigarachart/' + mcd);
				event.preventDefault();
			},
			copyToClipboard(meigaras){
				// console.log(meigaras);
				this.clipboardText = '';
				for(let i=0; i<meigaras.length; i++) {
					if(i != meigaras.length-1){
						this.clipboardText += meigaras[i].meigara_cd + ", ";
					}else{
						this.clipboardText += meigaras[i].meigara_cd;
					}
				}
				// console.log(this.clipboardText);
				navigator.clipboard.writeText(this.clipboardText);
				// console.log("copied");
				this.$toast.add({severity:'success', summary: '銘柄コピー', detail:'クリップボードに' + meigaras.length + '銘柄をコピーしました (' + this.clipboardText + ')', life: 2000});
			},
			toggle_setMeigaras(event, meigaras){
				this.$refs.menu1.toggle(event);
				this.copiedMeigaras = meigaras;
			},
			onShow(params) {
				this.clickedNews = params;
				console.log(this.clickedNews);

				this.$dialog.open(News, {
					props: {
						header: 'News Insight',
						style: {
								// width: '50vw',
								width: '70vw',
						},
						breakpoints:{
								'960px': '75vw',
								'640px': '90vw'
						},
						modal: true,
						dismissableMask: true
					},
					data: {
						newsData: this.clickedNews ? this.clickedNews : null,
						selectedCodeKey: this.selectedCodeKey ? this.selectedCodeKey : null,
						layout: this.layout ? this.layout : null,
						caller: 'meigaranewsrecentl',
					}
				});			
			}
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/prod/styles/badges.scss';
	@import '../assets/prod/styles/prices.scss';

	.accordion-r {
    margin-bottom: 1rem
  }
  .chip-r {
    margin: 2px
  }

	a {
    color: #515c66;
    // color: #ffffff;
  }
  a:hover {
    color: #4291dc;
    // text-decoration:underline;
  }
  .a-active {
    background-color: rgba(47, 142, 229, 0.4);
  }
  .text-active {
    color: #ffffff;
  }
	.img_link {
		cursor: pointer;
	}

	i:hover {
		color: #4291dc;
		cursor: pointer;
	}

	.subinfo-span {
		// color: rgba(81, 92, 102, 0.6);
		font-size: 12px;
	}

	.trendchart-img {
		opacity: 0.8;
		// border: solid 1px #cccccc;
		border-radius: 5px;  
		// width: 182px;
		width: 137px;
		height: 47px;
	}

	.span-highlight{
		color: #4291dc;
	}
	.rotate_text {
		transform:rotate(-90deg);
	}
	.date-span {
		color: rgba(81, 92, 102, 0.6);
		font-size: 11px;    
	}
	// .trendchart-img2 {
	// 	opacity: 0.8;
	// 	// border: solid 1px #cccccc;
	// 	border-radius: 5px;  
	// 	width: 100%;
	// 	height: 100%;
	// }
</style>
